.btn.btn-tab.btn-tab-feedback {
  font-family: 'Outfit', sans-serif;
  padding: 0.55rem 0.5rem;
  margin: 0 0.5rem;
}

.btn.btn-tab.btn-tab-feedback:first-child {
  margin-left: 0;
}

.btn.btn-tab.btn-tab-feedback:last-child {
  margin-right: 0;
}

.btn-valentine {
  background-color: rgba(235, 87, 87, 1);
  border-color: rgba(235, 87, 87, 1);
  color: #000;
}

.btn-valentine.bg-opacity-30 {
  background-color: rgba(235, 87, 87, 0.3);
  border-color: rgba(235, 87, 87, 0.3);
  color: #000;
}

.btn-seafoam {
  background-color: #08b89d;
  border-color: #08b89d;
  color: #000;
}

.btn-seafoam.bg-opacity-30 {
  background-color: rgba(8, 184, 157, 0.3);
  border-color: rgba(8, 184, 157, 0.3);
  color: #000;
}

.text-seafoam {
  color: rgb(8, 184, 157);
}
