.btn.btn-tab {
  font-size: 0.75rem;
  line-height: 0.75rem;
  min-width: 100px;
  border-radius: 8px;
  padding: 0.5rem;
  margin: 0 0.5rem;
}
.btn.btn-tab:first-child {
  margin-left: 0;
}
.btn.btn-tab:last-child {
  margin-right: 0;
}
.btn.btn-tab.no-toggle {
  pointer-events: none !important;
}

.btn-pastel {
  background-color: #d7a395;
  border-color: #d7a395;
  color: #000;
}

.btn-outline-pastel {
  background-color: transparent;
  border-color: #d7a395;
  color: #d7a395;
}
