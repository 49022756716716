.border-dotted {
  border: dotted 2px;
  padding: 8px 10px;
  color: #526b74;
  text-align: center;
}
.bg-lgray {
  background-color: #dbe5ea;
}

/* 50% darker than bg-lgray */
.bg-dgray {
  background-color: #c2cfd6;
}

.text-dgray {
  color: #526b74;
}
.box {
  padding: 10px 4px;
  background-color: white;
}
