.hr-line {
  position: relative;
  /*  new lines  */
}
.hr-line:after {
  position: absolute;
  display: block;
  content: ' ';
  height: 1px;
  width: auto;
  left: 85px;
  top: 50%;
  right: 0;
  background: rgb(0, 0, 0);
}
